<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
      @open="opDialog"
    >
    <div class="content" @click='showtype=false'>
      <div class="all">
        <el-container>
          <!-- 左侧大模块 -->
          <el-main class="main_left">
            <div class="main_title">报装工单信息</div>
            <div class="form">
              <span class="img"></span>
              <span class="title">申请信息</span>
              <el-form ref="addform" :model="form"  size="mini" class="form_user">
                <el-form-item label="申请单号:">
                 {{form.applyDetail.applyCode}}
                </el-form-item>
                <el-form-item label="用气类型:" prop="positionName">
                 {{form.applyDetail.typeName}}
                </el-form-item>
                <el-form-item :label="form.applyDetail.type==1?'法人姓名:':'产权人姓名:'" prop="positionName">
                 {{form.applyDetail.name}}
                </el-form-item>
                <el-form-item :label="form.applyDetail.type==1?'法人身份证号:':'身份证号:'" prop="positionName">
                 {{form.applyDetail.idcard}}
                </el-form-item>
                <el-form-item label="联系人电话:" prop="positionName">
                 {{form.applyDetail.tel}}
                </el-form-item>
                <el-form-item label="公司名称:" prop="positionName" v-if="form.applyDetail.type==1">
                 {{form.applyDetail.corporation}}
                </el-form-item>
                <el-form-item :label="form.applyDetail.type==1?'公司地址:':'地址:'" prop="positionName">
                {{form.applyDetail.address}}
                </el-form-item>
                <el-form-item label="用途:" prop="positionName">
                 {{form.applyDetail.useDescName}}
                </el-form-item>
              </el-form>
              <span class="img"></span>
              <span class="title">工单信息</span>
              <el-form ref="addform" :model="form"  size="mini" class="form_user">
                <el-form-item label="工单号:" prop="positionName">
                 {{form.orderDetail.orderCode}}
                </el-form-item>
                <el-form-item label="用户类型:" prop="positionName">
                 {{form.orderDetail.userTypeCodeName}}
                </el-form-item>
                <el-form-item label="用气设备:" prop="positionName">
                 {{form.orderDetail.equipmentCodeName}}
                </el-form-item>
                <el-form-item label="执行位置:" prop="positionName">
                 {{form.orderDetail.address}}
                </el-form-item>
                <el-form-item label="测量员:" prop="positionName">
                 {{form.orderDetail.surveyorName}}
                </el-form-item>
                <el-form-item label="工单状态:" prop="positionName">
                 {{form.orderDetail.orderStatusName}}
                </el-form-item>
                <el-form-item label="执行简图:" prop="positionName" v-if="form.orderDetail.imageUrlList.length>0">
                 <div>
                 <el-image 
                style="width: 100px; height: 100px"
                :src="form.orderDetail.imageUrlList[0]" 
                :preview-src-list="form.orderDetail.imageUrlList">
                </el-image>
                <span style="margin-left:10px;position: absolute;top: 60px;">共{{form.orderDetail.imageUrlList.length}}张</span>
                </div>
                </el-form-item>
              </el-form>
            </div>
          </el-main>
          <!-- 右侧大模块 -->
          <el-main class="main_right">
            <div class="main_title">报装进程信息</div>
            <div class="block">
              <el-timeline :reverse="reverse">
                <el-timeline-item
                  v-for="(activity, index) in activities"
                  :key="index"
                  color='#077DFF'
                  >
                  <div class="order">                                                                                                                                                                                                                                                                                                                                                                                                    
                    <span class="order_title">
                      {{activity.result}}
                    </span>
                    <div class="order_desc" 
                    v-if="activity.nodeCode==1">
                      <span style="margin-left:2%">
                        创建人:{{activity.realname}}
                      </span>
                      <span style="margin-left:8%">
                        创建时间:{{activity.createTime}}
                      </span>
                      <span class="button_des" @click="showDesc(activity.id,activity.nodeCode)">查看</span>
                    </div>
                    <div class="order_desc" 
                    v-if="activity.nodeCode==2||activity.nodeCode==7">
                      <span style="margin-left:2%">
                        审批人:{{activity.realname}}
                      </span>
                      <span style="margin-left:8%">
                        审批时间:{{activity.createTime}}
                      </span>
                      <span class="button_des"  @click="showDesc(activity.id,activity.nodeCode)">查看</span>
                    </div>
                     <div class="order_desc" 
                    v-if="activity.nodeCode==3">
                      <span style="margin-left:2%">
                        设计人:{{activity.realname}}
                      </span>
                      <span style="margin-left:8%">
                        设计时间:{{activity.createTime}}
                      </span>
                      <span class="button_des"  @click="showDesc(activity.id,activity.nodeCode)">查看</span>
                    </div>
                    <div class="order_desc" 
                    v-if="activity.nodeCode==4">
                      <span style="margin-left:2%">
                        预算人:{{activity.realname}}
                      </span>
                      <span style="margin-left:8%">
                        预算时间:{{activity.createTime}}
                      </span>
                      <span class="button_des"  @click="showDesc(activity.id,activity.nodeCode)">查看</span>
                    </div>
                    <div class="order_desc" 
                    v-if="activity.nodeCode==6||activity.nodeCode==5">
                      <span style="margin-left:2%">
                        申请人:{{activity.realname}}
                      </span>
                      <span style="margin-left:8%">
                        申请时间:{{activity.createTime}}
                      </span>
                      <span class="button_des"  @click="showDesc(activity.id,activity.nodeCode)">查看</span>
                    </div>
                     <div class="order_desc" 
                    v-if="activity.nodeCode==13||activity.nodeCode==14">
                      <span style="margin-left:2%">
                        操作人:{{activity.realname}}
                      </span>
                      <span style="margin-left:8%">
                        操作时间:{{activity.createTime}}
                      </span>
                      <span class="button_des"  @click="showDesc(activity.id,activity.nodeCode)">查看</span>
                    </div>
                    <div class="order_desc" 
                    v-if="activity.nodeCode==8">
                      <span style="margin-left:2%">
                        签约人:{{activity.realname}}
                      </span>
                      <span style="margin-left:8%">
                        签约时间:{{activity.createTime}}
                      </span>
                      <span class="button_des"  @click="showDesc(activity.id,activity.nodeCode)">查看</span>
                    </div>
                     <div class="order_desc" 
                    v-if="activity.nodeCode==9">
                      <span style="margin-left:2%">
                        确认时间:{{activity.createTime}}
                      </span>
                    </div>
                     <div class="order_desc" 
                    v-if="activity.nodeCode==10">
                      <span style="margin-left:2%">
                        交底时间:{{activity.createTime}}
                      </span>
                    </div>
                     <div class="order_desc" 
                    v-if="activity.nodeCode==11">
                      <span style="margin-left:2%">
                        开工时间:{{activity.createTime}}
                      </span>
                    </div>
                     <div class="order_desc" 
                    v-if="activity.nodeCode==12">
                      <span style="margin-left:2%">
                        验收时间:{{activity.createTime}}
                      </span>
                      
                    </div>
                     <!-- <div class="order_desc" 
                    v-if="activity.nodeCode==12">
                      <span style="margin-left:2%">
                        验收时间:{{activity.realname}}
                      </span>
                      
                    </div> -->
                    
                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
          </el-main>
        </el-container>
      </div>
    </div>
    </el-dialog>
    <select-info :id="id" :type='type' ref="selectInfo"></select-info>
  </div>
</template>
<script>
import {bzWorkOrderCheck_getProcessDetail} from '../../RequestPort/selectQuery/select'
import selectInfo from './selectInfo.vue'
export default {
  name: 'selectDesc',
  components: {selectInfo},
  data() {
    return {
      form:{applyDetail:{applyCode:'',},orderDetail:{imageUrlList:[]}},
      id:'',
      reverse: false,
      activities: [],
      dialogVisible:false,
      id:'',
      type:''
    }
  },
   props: {
    id: {
      type: String,
    }
  },
  mounted() {
    // this.id = this.$route.query.id;
    // this.getInfo(this.id)
  },
  methods: {
    getInfo(id){
      bzWorkOrderCheck_getProcessDetail({applyId:id}).then(res=>{
        this.form = res.data;
        if(!this.form.orderDetail.imageUrlList){
          this.form.orderDetail.imageUrlList = [];
        }
        this.activities = this.form.processNodeVOList;
      })
    },
     showDesc(id,type){
       this.id = id+''
       this.type = type;
       this.$refs.selectInfo.setdialogVisible(true)
     },
     opDialog(){
       this.getInfo(this.id)
     },
     clearnF(){
       this.form={applyDetail:{applyCode:'',},orderDetail:{imageUrlList:[]}}
     },
     setdialogVisible(flag){
          this.dialogVisible = flag;
      }
  }
}
</script>

<style  lang="less" scoped>
::v-deep.el-timeline-item__wrapper{top:-15px}
.all {
  background-color:#fff;
  height:100%;
}
.content {
  background: #fff;
  width: 100%;
  border-radius: 6px;
}
.main_title{//大板块的总标题
  border-bottom: 1px solid #ECEDF1;
  font-size: 20px;
  padding-bottom: 18px;
}
.main_left{
  margin: 10px;
  background-color: white;
  border-radius: 5px;
  
  overflow:auto;
}
.main_right{
  margin: 10px;
  margin-left: 0px;
  background-color: white;
  border-radius: 5px;
  min-width:890px;
}
.img {
  border: 3px solid #3f74d5;
  margin-right: 2px;
  height: 16px;
}
.form_user{
  margin: 20px;
}
.title{
  font-size: 18px;
  margin-left: 13px;
}
.form{
  margin: 10px;
}
.block{
  margin:20px;
}
.order{
  width: 100%;
 
   height: 40px;
  line-height: 40px;
}
.order_title{
  font-size: 16px;
  margin-left: 2%;
  color: #0F71E2;
  display:inline-block;
  width: 140px;
  overflow: hidden;
 vertical-align: bottom;
}
.order_desc{
  font-size: 14px;
  background-color: #F6F7FC;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  color: #444444;
   display:inline-block;
   width: 80%;
}
.button_des{
  background: #FFFFFF;
border: 1px solid #1082FF;
border-radius: 4px;
display: inline-block;
width: 54px;
height: 28px;
line-height: 28px;
text-align: center;
color: #1082FF;
float:right;
margin: 5px;
}
</style>
