<template>
  <div>
    <div class="content" @click='showtype=false'>
      <div class="all">
        <div class="SearchBar">
          <!--搜索框-->
          <el-form ref="search" :inline="true" :model="search" size="small" >
            <el-form-item label="创建时间" class="lab">
              <el-date-picker
                class="SearchDate"
                v-model="searchDatatime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="报装来源" class="lab">
              <el-select v-model="search.sourceType" clearable placeholder="请选择" @change="typyChange" class="SearchOptions">
                <el-option
                v-for="item in sourceList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="用户类型" class="lab">
              <el-select v-model="search.userTypeCode" clearable placeholder="请选择" @change="typyChange" class="SearchOptions">
                <el-option
                v-for="item in useList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="测量员" class="lab">
              <el-input v-model="search.surveyorName" clearable></el-input>
            </el-form-item>
            <el-form-item label="工单状态" class="lab">
              <el-select v-model="search.orderStatus" clearable placeholder="请选择" class="SearchOptions">
                <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="工单号" class="lab">
              <el-input v-model="search.orderCode" clearable></el-input>
            </el-form-item>
            <span style="float:right;width:150px">
            <el-button type="primary" class="SearchBtn" size="small" @click="getList(1)">查询</el-button>
            <el-button type="primary" plain class="SearchBtn2" size="small" @click="reset">重置</el-button>
            </span>
          </el-form>
        </div>
        <div class="table">
          <div class="table-height-in">
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 15px 0;"
            border
             
            :stripe="true"
            
          >
           <el-table-column
                type="index"
                label="序号"
                align="center"
                :index="(parseInt(search.current)-1)*parseInt(search.size)+1"
                >
                </el-table-column>
            <el-table-column
              prop="orderCode"
              label="工单号"
              align="center"
              show-overflow-tooltip
              
            >
            </el-table-column>
            <el-table-column
              prop="applyCode"
              label="申请单号"
              align="center"
              show-overflow-tooltip
              
            >
            </el-table-column>
            <el-table-column
              prop="userTypeCodeName"
              label="用户类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
             <el-table-column
              prop="equipmentCodeName"
              label="用气设备"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
             <el-table-column
              prop="surveyorName"
              label="测量员"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            
           
            <el-table-column
              prop="createTime"
              label="创建时间"
              align="center"
              show-overflow-tooltip
             
            >
            </el-table-column>
             
            <el-table-column
              prop="statusName"
              label="状态"
              align="center"
              show-overflow-tooltip
             
            >
            <template slot-scope="{ row }">
              <el-tag v-if="row.orderStatus==1" type="primary" style="background-color:#1C64F6 ;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==2" type="info" style="background-color:#EFAC0B;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==3" type="success" style="background-color:#CD9340;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==4" type="warning" style="background-color:#28C8F0;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==5" type="primary" style="background-color:#FF6C32;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==6" type="info" style="background-color:#449BFF;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==7" type="success" style="background-color:#32C655;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==8" type="warning" style="background-color:#5773DC;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==9" type="primary" style="background-color:#FF9C32;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==10" type="info" style="background-color:#83CF5D;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==11" type="success" style="background-color:#F55151;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==12" type="success" style="background-color:#BFBFBF;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
            </template>
            </el-table-column>
            <el-table-column prop="operation" label="操作" align="left" width="100">
              <template slot-scope="{ row }">
                    <el-button  
                    type="text"
                    size="small" @click="goPath(row.applyId)"
                    >查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          </div>
          <div class="pageBox">
          <el-pagination
            :current-page="search.current"
            :background="true"
            :page-sizes="[30, 50, 100]"
            :page-size="search.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        </div>
      </div>
    </div>
    
    <select-desc :id='id' ref="selectDesc"></select-desc>
  </div>
</template>
<script>
import {selectDictListByParentCode } from '@/apis/commonType'
import {bzWorkOrderCheck_pageProcess} from '../../RequestPort/selectQuery/select'
import selectDesc from './selectDesc.vue'
export default {
  name: 'selectAll',
  components: {selectDesc},
  mixins: [],
  data() {
    return {
      sourceList:[{ label: '爱山东', value: '1' },{ label: '掌上聊城', value: '2' },{ label: '公众号', value: '3' },{ label: '官网', value: '4' },{ label: '客服提交', value: '5' }],
      statusList:[{ label: '已登记', value: '1' },{ label: '待审核', value: '2' },{ label: '已审核', value: '3' },{ label: '已出设计', value: '4' },{ label: '已处预算', value: '5' },{ label: '已签约', value: '6' },{ label: '合同确认完成', value: '7' },{ label: '正式设计', value: '8' },{ label: '施工中', value: '9' },{ label: '验收完成', value: '10' },{ label: '已终止', value: '11' },{ label: '已作废', value: '12' }],
      
      useList:[],
      search: { current: 1, size: 100,timeType:'1'}, //搜索
      searchDatatime:[],
      total: 10,
      tableData: [], //列表对象
      id:'',
      type:'',
    }
  },
  mounted() {
    this.getOption();
    this.searchDatatime = this.getdate();
    this.getList();
  },
  methods: {
      getdate(){
        var myDate = new Date();
        var month = parseInt(myDate.getMonth())+1;
        const month1 = month +1;
        month = (Array(2).join(0) + month).slice(-2)
        var  day = '01';
        var dateStart = myDate.getFullYear()+"-"+month+"-"+day;
        
         
        var curDate= ''
        if(month1==13){
            curDate = new Date(myDate.getFullYear()+1+'/01/1');
        }else{
            curDate = new Date(myDate.getFullYear()+"/"+month1+'/1');
        }
        const preDate = new Date(curDate.getTime() - 24*60*60*1000).getDate();
        var dateEnd = myDate.getFullYear()+"-"+month+"-"+preDate;
        return [dateStart,dateEnd];

      },
      getOption(){
       
          selectDictListByParentCode({parentCode:'YH_TYPE'}).then(res=>{
          if(res.code==200){
            this.useList= res.data
            //  selectDictListByParentCode({parentCode:'70000'}).then(res=>{
            //   if(res.code==200){
            //     var data2 = res.data
            //     this.useList = data1.concat(data2)
            //   }
            // })
          }
        })
       
        
      },
      typyChange(val){
        this.getOption();
      },
      //重置查询条件
    reset() {
      ;(this.search = {
        current: 1,
        size: 100,
        timeType:'1'
      }),
      this.useList =this.getOption();
       this.searchDatatime=this.getdate();
        this.getList(1)
    },
    getList(current) {
      this.search.startTime = ''
        this.search.endTime = ''
      if(this.searchDatatime){
        this.search.startTime = this.searchDatatime[0]
        this.search.endTime = this.searchDatatime[1]
      }
      
      if (current) {
        this.search.current = 1
      }
      //查列表
      bzWorkOrderCheck_pageProcess(this.search).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
   
    //跳转
    goPath(id) {
      this.id = id.toString()
      this.$refs.selectDesc.setdialogVisible(true)
    },
    handleSizeChange(val) {
      this.search.size = val
      this.getList(1)
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.getList()
    },
   
  }
}
</script>

<style  lang="less" scoped>
::v-deep .el-upload--picture-card{width:100px;height:100px;line-height:100px}
::v-deep .el-upload-list--picture-card .el-upload-list__item{width:100px;height:100px;line-height:100px}
.searchType{width:210px;box-shadow: 1px 1px 5px #888888;position:absolute;z-index:100;background-color:#fff}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.SearchOptions{
  width: 150px;
}
.SearchOptions2{
  width: 100px;
}
.SearchDate{
  width: 300px;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
   background-color: #ffffff;
   height: auto
}
.all {
  background-color:#f3f4f8;height: auto;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  height: calc(100vh-70px);
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 1%;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList{
  display:block;margin:10px 0px
}
</style>
